.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Courgette');
@import url('https://fonts.googleapis.com/css2?family=Anta');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Lexend');

.myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.1;
  z-index:1;
}
.responseMsg{
  color:green;
  font-size:20px;
}

.bg3{
  background-color: rgba(0, 0, 0, 0.9);
  height:100px;
  z-index:1;
  position: fixed;
  bottom: 0;
}

.bg2{
  z-index:2;
  height:100px;
  z-index:2;
}

.panel{
  background-color: rgba(0, 0, 0, 0.9);
  width:100%; 
  z-index:2;
  font-family: "Anta", sans-serif;
}

.logo0{
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size:140px;
  background: linear-gradient(#ffffff00, #000000ff);
  width:100%;
  z-index:2;
}


.icon{
  position:absolute;
  top:120px;
  width:120px;
  height:120;
  z-index:2;
}

.logo1{
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size:200px;
  background: linear-gradient(#ffffff00, #000000ff);
  width:100%;
  z-index:2;
}

.teaser{
  font-size:40px;
  background: linear-gradient(#000000, #00000033);
  z-index:2;
  width:100%;
}


.sendBtn{
  align-items: center;
  background: #b9f1be;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  color: #444444;
  display: inline-flex;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 14px;
  overflow-wrap: break-word;
  padding: 10px;
  text-decoration: none;
  width: auto;
  height: 35px;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius: 10%;
  border: 0 solid #0ba65b;

}
.inp{
  margin-top:15px;
  width:270px;
  height:35px;
  border-radius: 5%;
  border: 1px solid #2d9fd9;
  z-index:2;
  vertical-align: bottom
}

.custom-input{
  font-size: 20px !important;
  margin: 1em 0em !important;
}

.details{
  font-size:30px;
  background: linear-gradient(#000000ff, #000000ff);
  z-index:0;
  width:100%;
}

.action{
  font-family: "Lexend", sans-serif;
  font-size:23px;
  background: linear-gradient(#000000ff, #000000ff);
  color:gray;
  z-index:1;
  width:100%;
}

.header{
  font-family: "Anta", sans-serif;
  font-size:15px;
  position: absolute; /* Set div position to relative */
  top:0;
  color:rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.9);
  width:100%;
  z-index:2;
}

.footer{
  font-family: "Lexend", sans-serif;
  font-size:13px;
  position: absolute; /* Set div position to relative */
  bottom:0;
  color:rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.4);
  width:100%;
  z-index:2;
}

a:link { 
  text-decoration: none; 
  color:rgb(205, 193, 197);
} 
a:visited { 
  text-decoration: none; 
  color:rgb(205, 193, 197);
} 
a:hover { 
  text-decoration: none; 
  color:rgb(205, 193, 197);
} 
a:active { 
  text-decoration: none; 
  color:rgb(205, 193, 197);
}

.anta-regular {
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.App-header {
  background-color: #0f1013;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://media.tones.studio/img/bg1.png"); 
  z-index:2;
  height:100px;
  z-index:2;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
